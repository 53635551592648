#useful-links {
  border-bottom: none;

  .lots-links-wrapper {
    padding-top: 15px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .link {
    margin: 0 10px;
    padding-bottom: 3px;
    color: rgb(71, 137, 196);
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
    text-align: center;
    border-bottom: 1px solid rgb(71, 137, 196);
    text-decoration: none;
  }
}

@import "../../../../themes/general/variables.scss";

.useful-links-section {
  .form-row {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .row-content {
    align-items: flex-end;

    .file-label {
      &.disabled {
        cursor: no-drop;
      }
    }

    .type-url {
      margin-top: 5px;
      padding-left: 25px;


      &.disabled {
        cursor: no-drop;
      }
    }

    .documents-text-wrapper {
      &.type-add-pdf {
        @media (max-width: $medium-width) {
          width: 180px;
        }
        @media (max-width: $mobile-width) {
          width: 100%;
        }
      }
    }

  }

  .section-body {
    position: relative;
  }

  .url-error {
    width: 100%;
    margin: auto;
    position: absolute;
    bottom: 5px;
    color: red;
    font-size: 14px;
    text-align: center;
  }

  .doc-btn-add {
    margin-top: 0;
    margin-left: 15px;
  }
}
.custom-select-wrapper {
    position: relative;
    .custom-select {
        display: flex;
        height: 44px;
        background-color: #ededed;
        border: 1px solid #c6c6c6;
        border-radius: 5px;
        box-shadow: none;
        width: 270px;
        padding-left: 10px;
        cursor: pointer;
        overflow: hidden;
        .custom-select-container {
            display: flex;
            align-items: center;
            width: 214px;
            padding-right: 10px;
            .custom-select-input {
                background-color: transparent;
                cursor: inherit;
                border: none;
                color: #666666;
                font: 400 14px Lato;
              }
              .custom-select-value {
                  display: flex;
                  color: #666666;
                  align-items: center;
                  font: 400 14px Lato;
              }
        }
        .custom-select-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            border-color: #c6c6c6;
            flex: 0 0 44px;
            border-left: 1px solid #c6c6c6;
            border-radius: 5px 0 0 5px;
            font-size: 29px;
            color: #5d5d5d;
        }
    }
    .custom-select-options {
        width: 270px;
        height: 290px;
        position: absolute;
        z-index: 100;
        overflow-y: scroll;
        border-left: 1px solid #c6c6c6;
        border-bottom: 1px solid #c6c6c6;
        border-right: 1px solid #c6c6c6;
        .custom-select-option {
            display: flex;
            align-items: center;
            height: 32.5px;
            background-color: #ffffff;
            color: #666666;
            padding-left: 10px;
            font: 400 14px Lato;
            cursor: pointer;
            &:hover {
                background-color: #e9e9e9;
            }
            &.active {
                background-color: #acabab;
                font-weight: 600;
            }
            &.empty {
                display: flex;
                justify-content: center;
                color: #999999;
                cursor: text;
            }
            &.empty:hover {
                background-color: #ffffff;
            }
            p {
                margin: 0;
            }
        }
    }
}

.adjust-dropdown {
    .custom-select {
      width: 180px;
    }
    .custom-select-options {
        width: 180px;
    }
  }